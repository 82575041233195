#experience-box{
    margin-bottom: 5%;
    background-color: white;
    border-radius: 10px;
    padding: 2%;
    padding-bottom: 3%;
    position: relative;
}

#dashed-frame-exp-1{
    border: 2px dashed #BD9EFF;
    height: 45%;
    padding: 5%;
    width: 90%;
    top: -10%;
    left: -2%;
    border-radius: 10px;
    position: absolute;
    z-index: -2;
    display: none;
    
}

#solid-frame-exp-1{
    border: 2px solid #ffb4da;
    height: 45%;
    padding: 5%;
    width: 90%;
    top: -5%;
    left: -1%;
    border-radius: 10px;
    position: absolute;
    z-index: -2;
    display: none;
    
}

#dashed-frame-exp-2{
    border: 2px dashed #ffb4da;
    height: 45%;
    padding: 5%;
    width: 90%;
    bottom: -10%;
    right: -2%;
    border-radius: 10px;
    position: absolute;
    z-index: -2;
    display: none;
    
}

#solid-frame-exp-2{
    border: 2px solid #BD9EFF;
    height: 45%;
    padding: 5%;
    width: 90%;
    bottom: -5%;
    right: -1%;
    border-radius: 10px;
    position: absolute;
    z-index: -2;
    display: none;
    
}

.exp-image {
    width: 65%;
    height: 100%;
    margin-left: 2%;
}

.experience-image {
    width: 100%;
    height: 100%;
}


.desc-and-image {
    display: flex;
    align-items: center;
}
.exp-title{
    font-size: 3em;
    font-family: 'Archivo-black';
    color: #FFB4DA;
}

.exp-title a{
    color: #FFB4DA;
    text-decoration: none;
}

.exp-title a:hover{
    text-shadow: 0.1em 0.1em 0em #C7F5FA;
}


.exp-pos{   
    font-family: 'Archivo-black';
    color: #F4D94C;
    font-size: 2em;
}

.exp-dates{
    font-family: 'Archivo-black';
    color: #56CCF2;
    font-size: 2em;
}

.text-for-exp {
    width: 100%;
}

.exp-desc{
    font-family: 'Cabin';
    font-size: 1.7em;
    width: 90%;
}