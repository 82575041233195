.navbar{
    display: flex;
    width: 100%;
    font-family: 'Archivo-Black';
    position: fixed;
    top: 0;
    justify-content: right;
    align-items: center;
    height: 5em;
    z-index: 2;
    background-color: #fffaed;
}


.navbar ul{
    width: 100%;

    display: flex;
    justify-content: flex-end;

    list-style-type: none;

    margin: 0;
    padding: 0;
}

li a {
    display: block;
    text-align: right;

    color: black;
    text-decoration: none;
    font-family: 'Cabin';
    font-size: 1.4em;

    backdrop-filter: blur(5px);
    border-radius: 50px;
    border: solid;
    border-color: transparent;

    margin-left: 8px;
    margin-right: 8px;
    padding-left: 8px;
    padding-right: 8px;

    z-index: 9999999;
}



.navbar li:hover{    
    text-decoration: underline wavy #ffb4da;
}
